import { useSession } from "components/auth/hooks/SessionContext";
import HeaderLink from "./components/HeaderLink";
import Feature from "components/Feature";
import Login from "components/auth/Login";
import Logout from "components/auth/Logout";
import {
  innerHeaderStyle,
  logoImageStyle,
  logoLinkStyle,
} from "./styles";
import { Stack, Text } from "@fluentui/react";
import { TAB_HUB_DISABLE } from "../../metis";
import { CatalogFilter } from "pages/Catalog2/Catalog.Filter";
import { HUB_URL } from "utils/constants";


interface CatalogBannerProps {
  filter?: any | undefined;
  onFilterChange?: any //(new_value: any, field: string)=> void;
}


export const HeaderBar: React.FC<CatalogBannerProps> = ({
  filter,
  onFilterChange
}) => {
  
  return (
    <Stack
      className={innerHeaderStyle}
      horizontal
      wrap
      verticalAlign="center"
      tokens={headerTokens}
    >
      <Stack horizontal verticalAlign="center" tokens={headerTokens}>
        <HeaderLink isNav to="/"
          className={logoLinkStyle}
          aria-label="EO Catalogue"
        >
          <img
            alt=""
            className={logoImageStyle}
            src="./images/eo-catalogue/eocatalogue-logo/eocatalogue-logo.svg"
            role="presentation"
            aria-hidden="true"
          />
        </HeaderLink>
        <HeaderLink isNav to="/explore">
          Explore
        </HeaderLink>
        <HeaderLink isNav to="/catalog">
          Datasets
        </HeaderLink>
      </Stack>

      <CatalogFilter filter={filter} onFilterChange={onFilterChange} />

      <Stack horizontal verticalAlign="center" tokens={headerTokens}>
        {!TAB_HUB_DISABLE ?
          (<HeaderLink external to={HUB_URL}>
            Hub
          </HeaderLink>)
          : ''
        }
        <HeaderLink isNav to="/applications">
          Applications
        </HeaderLink>
        <HeaderLink isNav to="/docs">
          Documentation
        </HeaderLink>

        <Feature name="login">
          <Login />
          <Logout />
        </Feature>
      </Stack>
        
      
    </Stack>
  );
};

const headerTokens = { childrenGap: "10px 21px" };
const authSectionTokens = { childrenGap: 4 };
