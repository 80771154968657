import { mergeStyles } from "@fluentui/react";

export const catalogFilterStyle = mergeStyles({
  flexGrow: 1,
  margin: 0,

  maxWidth: "512px",
  borderRadius: "7px",
  borderWidth: "2px"
});
