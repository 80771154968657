import {
  getTheme,
  IconButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { TAB_HUB_DISABLE } from "../../metis";
import HeaderLink from "./components/HeaderLink";
import Feature from "components/Feature";
import Login from "components/auth/Login";
import Logout from "components/auth/Logout";
import {
  headerOverflowStyle,
  headerPipeStyle,
  logoImageStyle,
  logoLinkStyle,
  productNameStyle,
  smallHeaderStyle,
} from "./styles";
import { HUB_URL } from "utils/constants";

export const HeaderOverflow: React.FC = () => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  return (
    <Stack
      className={smallHeaderStyle}
      horizontal
      tokens={headerTokens}
      horizontalAlign="space-between"
    >
      <Stack horizontal>
        <a
          className={logoLinkStyle}
          href="https://www.csgroup.eu/"
          aria-label="EOCatalogue"
        >
          <img
            alt=""
            className={logoImageStyle}
            src="./images/eo-catalogue/eocatalogue-logo/eocatalogue-logo.svg"
            role="presentation"
            aria-hidden="true"
          />
        </a>
      </Stack>
      <StackItem>
        <IconButton
          ariaLabel="Navigation overflow menu"
          iconProps={overflowIconProps}
          onClick={openPanel}
        />
        <Panel
          isLightDismiss
          isOpen={isOpen}
          onDismiss={dismissPanel}
          type={PanelType.custom}
          customWidth="200px"
          className={headerOverflowStyle}
        >
          <Stack tokens={panelLinkTokens} verticalAlign="start" style={{paddingTop: "20px"}}>
            <HeaderLink className="white_bond a_hover_grey" align="start" isNav to="/explore">
              Explore
            </HeaderLink>
            <HeaderLink className="white_bond a_hover_grey" align="start" isNav to="/catalog">
              Datasets
            </HeaderLink>           
            <HeaderLink className="white_bond a_hover_grey" align="start" isNav to="/applications">
              Applications
            </HeaderLink>
            {!TAB_HUB_DISABLE ?
            <HeaderLink className="white_bond a_hover_grey" align="start" isNav to={HUB_URL}>
              Hub
            </HeaderLink>
            : null
            }
            <Feature name="login">
              <Login />
              <Logout />
            </Feature>
          </Stack>
        </Panel>
      </StackItem>
    </Stack>
  );
};

const theme = getTheme();

const overflowIconProps = {
  iconName: "GlobalNavButton",
  styles: {
    root: {
      fontSize: 20,
      height: 20,
      lineHeight: 20,
      color: theme.semanticColors.bodyText,
    },
  },
};
const panelLinkTokens = { childrenGap: 15 };
const headerTokens = { childrenGap: "10px 21px" };
