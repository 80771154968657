import { mergeStyles, getTheme } from "@fluentui/react";

const theme = getTheme();

export const headerStyle = mergeStyles({
  padding: "11px 0",
  background: theme.semanticColors.bodyBackground,
  borderBottom: "1px solid rgb(237, 235, 233)",
  position: "sticky",
  top : 0,
  zIndex: 10
});

export const innerHeaderStyle = mergeStyles({
  margin: "0 20px",
  "@media not (min-width: 996px)": {
    display: "none",
  },
  "& > * > :nth-child(1), & > * > :nth-child(3)": {
    display: 'flex',
    minWidth: 'fit-content',
    flex: 1
  },
  "& > * > :nth-child(3)": {
    justifyContent: 'flex-end'
  }
});

export const smallHeaderStyle = mergeStyles({
  paddingLeft: "20px",
  paddingRight: "20px",
  alignItems: "center",

  "@media (min-width: 996px)": {
    display: "none",
  },

  "& > *": {
    alignItems: "center"
  }
});

export const headerOverflowStyle = mergeStyles({
  "a": {
    color: theme.semanticColors.bodySubtext,
  },
  "a.active, a.active:hover, a.active:focus": {
    color: theme.semanticColors.link + "!important"
  }
});

export const logoLinkStyle = mergeStyles({
  outlineOffset: "-2px",
  display: "flex",
  alignItems: "center",
  padding: "0",
  fontSize: "23.5px",
  fontWeight: 500,

  marginTop: "0 !important"
});

export const logoImageStyle = mergeStyles({
  height: "48px",
  lineHeight: "1",
});

export const headerPipeStyle = mergeStyles({
  marginTop: "0px !important",
  marginLeft: "7px !important",
  marginRight: "14px !important",
  fontSize: "23.5px",
  fontWeight: 500,
  lineHeight: "1",
});

export const productNameStyle = mergeStyles({
  marginLeft: "-3px !important",
  fontWeight: "600",
});

/* Tablet size main header will break on first header link to new line */
export const breakStyle = mergeStyles({
  flexBasis: "100%",
  height: "0",
  margin: "0",
  "@media (min-width: 1023px)": {
    display: "none",
  },
});
