import { headerStyle } from "./styles";
import { gridContentStyle, offGridContentStyle } from "styles";
import { HeaderBar } from "./Header.Bar";
import { HeaderOverflow } from "./Header.Overflow";
import { CatalogBanner } from "pages/Catalog2/Catalog.Banner";
import { updateQueryStringParam } from "pages/Explore/utils";
import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { CatalogCollectionList } from "pages/Catalog2/Catalog.CollectionList";
import { CatalogFilteredCollectionList } from "pages/Catalog2/Catalog.FilteredCollectionList";
import { CatalogToc } from "pages/Catalog2/Catalog.Toc";

const initialFilter =  {
  filterText: "",
} 

const Header = ({ onGrid = true }) => {
  const navClass = onGrid ? gridContentStyle : offGridContentStyle;
  const [filter, setFilter] = useState<any>(getInitialFilter);

  useEffect(() => {
    updateQueryStringParam("filter", filter.filterText);
  }, [filter.filterText]);

  const handleFilter = (new_value: any, filter_fields: string[]) => {
    const new_obj = {...filter};
    for (let field of filter_fields) (new_obj as any)[field] = new_value;
    setFilter(new_obj);
  };


  const renderSearch = () => {
    if(filter.filterText?.length >= 1) {
      return(
        <Stack horizontal className="grid-content">
        {!filter?.filterText && <CatalogToc />}
        {!filter?.filterText && <CatalogCollectionList setFilterText={setFilter} />}
        {filter?.filterText && (
          <CatalogFilteredCollectionList
          setFilter={handleFilter}
          filter={filter}
          />
        )}
        </Stack>
      )
    }
  };

  // const banner = (
  //   <CatalogBanner filter={filter} onFilterChange={handleFilter} />
  // );

  return (
    <header className={headerStyle}>
      <nav className={navClass} aria-label="Main header navigation links">
        <HeaderBar onFilterChange={handleFilter} filter={filter}/>
        {renderSearch()}
        <HeaderOverflow />
      </nav>
    </header>
  );
};

const getInitialFilter = () => {
  const filtertext = new URLSearchParams(window.location.search).get("filter");
  return {...initialFilter, "filterText": filtertext || ""} 
};


export default Header;

