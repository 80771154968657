import CookieConsent, { Cookies } from "react-cookie-consent";



export const CookieComponent: React.FC = () => {



    // for getting the value of the cookie and use it in the code just use the getCookieConsentValue
    const cookieAccepted = (acceptOnScroll: boolean) => {
        // do something
        console.log("Cookie accepted")
    }

    const cookieDeclined = () => {
        // do something
        console.log("Cookie declined")
    }



    return (
        <CookieConsent
            location="bottom"
            buttonText="Ok"
            declineButtonText="No"
            cookieName="CookieConsent"
            onAccept={cookieAccepted}
            acceptOnScroll={false}
            enableDeclineButton
            onDecline={cookieDeclined}
            expires={150}
            buttonClasses="cookieButton"
            declineButtonClasses="cookieButton"

            style={{
                "color": "rgb(255, 255, 255)",
                "backgroundColor": " rgb(0, 0, 0)",
                "visibility": "visible"
              }}
            >
            <div className="cookieContainer">
                <p>
                    We use cookies to ensure that we give you the best experience on our website. 
                    If you continue to use this site we will assume that you are happy with it.  
                    For more information check the:  
                    <a href="https://gdpr.eu/privacy-policy/" 
                       target="_blank" 
                       style={{marginLeft: "3px", color: "#5191ef"}}
                    >Privacy policy</a>  
                </p>
            </div>
            
        </CookieConsent>
        );
    };
    
    
    
    