import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation, useParams } from "react-router-dom";

import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import DefaultBanner from "../../components/DefaultBanner";
import { useDocs, useServices } from "utils/requests";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { DOCS_URL } from "utils/constants";


const Documentation = () => {
  // Code CS ajouté
  const { id } = useParams();
  const [service, setService] = useState(null);
  const { isError, isLoading, isSuccess, data: stacResponse } = useDocs();	
  const [applications, setApplications] = useState([]);
  
  useEffect(() => {
    if (isSuccess) {
      const service = stacResponse.features;
            if (service) {
        setApplications(stacResponse.features)
      } else {
      }
    }
  }, [id, stacResponse, isSuccess]);

  const swagger = (
    <SwaggerUI url={`${DOCS_URL}`}/>
  );


  const banner = (
    <DefaultBanner>
      <h1>Documentation</h1>
      <p>
        Documentation for EO Data Catalog
        If you require access to the API please request access at <a onClick={() => window.location = 'mailto:yourmail@domain.com'}>support@cloudferro.com</a>
      </p>
    </DefaultBanner>
  );


//   const appsList = applications.map(app => {
//     return <ServicesCard key={`card-${app.id}`} app={app} />;
//   });

//   const storageList = appConfig.map(app => {
//     return <ApplicationCard key={`card-${app.title}`} app={app} />;
//   });


  

  return (
    <Layout bannerHeader={banner} isShort>
      <SEO title="Applications" />
      <div className="layout-container">
        <div className="layout-row grid-content">{swagger}</div>
      </div>
    </Layout>
  );
};

export default Documentation;
