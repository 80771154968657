import { IconButton, IIconProps, IStackTokens, SearchBox, Stack } from "@fluentui/react";
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useState } from "react";
import { CollectionFilter } from "types/stac";
import { CatalogFilterAdvanced } from "./Catalog.Filter.Advanced";
import { catalogFilterStyle } from "./styles";

interface CatalogFilterProps {
  filter: CollectionFilter;
  onFilterChange?: any // (new_value: any, field: string) => void;
  advanced?: boolean
}

const bannerTokens: IStackTokens = {
  childrenGap: 20,
};

const chevronDown: IIconProps = { iconName: 'ChevronDown' };
const chevronUp: IIconProps = { iconName: 'ChevronUp' };


export const CatalogFilter: React.FC<CatalogFilterProps> = ({
  filter,
  onFilterChange,
  advanced
}) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const onClear = () =>{
    onFilterChange("", ["filterText", "keywords"])
  }

  const onChangeFilterText = (e: any) =>{
    if (e?.type === 'input'){
      onFilterChange(e?.target.value, ["filterText", "keywords"])
    }
  }

  return (
    <>
      {!advanced 
      ? 
        <SearchBox
          placeholder="Browse Datasets"
          value={filter?.keywords || filter?.filterText}
          onChange={onChangeFilterText}
          onClear={onClear}
          data-cy="catalog-filter-input"
          className={catalogFilterStyle}
        />
      :
        <Stack  tokens={bannerTokens} style ={{"width": "100%"}}>
          <Stack>
            <Stack horizontal>
              <SearchBox placeholder="Filter dataset"
                       value={filter?.keywords || filter?.filterText}
                       onChange={onChangeFilterText}
                       onClear={onClear}
                       data-cy="catalog-filter-input"
                       styles={{ root: { width: "90%" } }} />
                
                <TooltipHost content="Advanced filter" calloutProps={{ gapSpace: 0 }}
                             closeDelay={200} // styles={{ root: { display: 'inline-block' } }} 
                >
                  <IconButton iconProps={ toggle ? chevronUp : chevronDown } onClick={() => {setToggle(!toggle)} }/>
                </TooltipHost>
            </Stack>
          </Stack>
          {toggle && <CatalogFilterAdvanced filter={filter} onFilterChange={onFilterChange} />}
        </Stack>
      }
    </>

  );
};

