export const apiRoot = window?._env_?.REACT_APP_API_ROOT || "";
const tilerRoot = window?._env_?.REACT_APP_TILER_ROOT || apiRoot;

export const STAC_URL = apiRoot;

// In some environments, there is a one-off URL for the STAC API that doesn't
//conform to the otherwise expected path layout
export const SERVICES_URL = apiRoot.endsWith("stac")
? apiRoot.replace("stac", "services")
: `${apiRoot}/api/sas/v1`;

export const SAS_URL = apiRoot.endsWith("stac")
  ? apiRoot.replace("stac", "sas")
  : `${apiRoot}/api/sas/v1`;

  export const DATA_URL = tilerRoot;

  export const DOCS_URL = apiRoot + "/openapi.yaml";

export const IMAGE_URL = window?._env_?.REACT_APP_IMAGE_API_ROOT || "";
export const HUB_URL = window?._env_?.REACT_APP_HUB_URL || "";
export const AUTH_URL = window?._env_?.REACT_APP_AUTH_URL || apiRoot;
export const ROOT_PATH = window?._env_?.REACT_APP_ROOT_PATH || "";

export const X_REQUEST_ENTITY = "X-PC-Request-Entity";
export const QS_REQUEST_ENTITY = "request_entity";
export const REQUEST_ENTITY = "explorer";

export const MOBILE_WIDTH = 430;

export const KEYCLOAK_URL = window?._env_?.REACT_APP_KEYCLOAK_URL || "";
export const KEYCLOAK_REALM = window?._env_?.REACT_APP_KEYCLOAK_REALM || "";
export const KEYCLOAK_CLIENT_ID = window?._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || "";
export const KEYCLOAK_CLIENT_SSO_PERSIST = window?._env_?.REACT_APP_KEYCLOAK_CLIENT_SSO_PERSIST || false;

export const SIGN_IN_ERROR_MESSAGE = "Please provide the following informations in the configuration to enable the Sign in:\n\n"
