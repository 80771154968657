import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";

import Layout from "components/Layout";
import SEO from "components/Seo";
import { CatalogBanner } from "./Catalog.Banner";
import { CatalogCollectionList } from "./Catalog.CollectionList";
import { CatalogFilteredCollectionList } from "./Catalog.FilteredCollectionList";
import { CatalogToc } from "./Catalog.Toc";
import { updateQueryStringParam } from "pages/Explore/utils";

import "styles/catalog.css";
import "./css/catalog.css";
import { initialFilter } from "./Catalog.Filter.Advanced";
import { CollectionFilter } from "types/stac";


export const Catalog: React.FC = () => {
  // const [filterText, setFilterText] = useState<string>(getInitialFilter);
  const [filter, setFilter] = useState<CollectionFilter>(getInitialFilter);
  const [isFilterUsed, setIsFilterUsed] = useState<boolean>(false);

  // Keep the URL in sync with the filter text
  useEffect(() => {
    updateQueryStringParam("filter", filter.filterText);
  }, [filter.filterText]);  
  

  useEffect(() => {
    setIsFilterUsed(false)
    Object.keys(filter).map(function(key){
         if (Array.isArray(filter[key as keyof CollectionFilter])){
          // @ts-ignore
          if (filter[key as keyof CollectionFilter] && filter[key as keyof CollectionFilter]?.length)
            setIsFilterUsed(true)   
         }
        
        else{
          if (filter[key as keyof CollectionFilter])
            setIsFilterUsed(true)   
          }
        });
  }, [filter]);

  
  const handleFilter = (new_value: any, filter_fields: string[]) => {
    const new_obj = {...filter};
    for (let field of filter_fields) (new_obj as any)[field] = new_value;
    setFilter(new_obj);
  };

  const handleKeywordsClick = (keyword:string) => {
    const new_obj = {...filter, 'keywords': keyword}
    setFilter(new_obj);
  };

  const banner = (
    <CatalogBanner filter={filter} onFilterChange={handleFilter}/>
  );

  return (
    <Layout bannerHeader={banner}>
      <SEO title="Data Catalog" />
      <Stack horizontal className="grid-content">
        {!isFilterUsed && <CatalogToc />}
        {!isFilterUsed && <CatalogCollectionList setFilterText={handleKeywordsClick} />}
        {isFilterUsed && (
          <CatalogFilteredCollectionList
            setFilter={handleFilter}
            filter={filter}
          />
        )}
      </Stack>
    </Layout>
  );
};

const getInitialFilter = () => {
  const filtertext = new URLSearchParams(window.location.search).get("filter");
  return {...initialFilter, "filterText": filtertext || ""} 
};
